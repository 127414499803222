<template>
    <div class="ratings"
        v-if="fullstars > 0">
        <i v-for="(n, index) in fullstars"
            class="fa fa-star"></i>
        <i v-if="halfstar"
            class="fa fa-star-half-o"></i>
        <i v-for="(n, index) in emptystars"
            class="fa fa-star-o"></i>
    </div>
</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'star-display',
    	props: {
    		stars: Number
    	},
    	data: function() {
    		return {
    			totalStars: 5
    		};
    	},
    	computed: {
    		fullstars: function() {
    			return parseInt(this.stars) || 0;
    		},
    		halfstar: function() {
    			var remaingStar = this.stars - parseInt(this.stars);
    			return (remaingStar >= 0.25 ? true : false);
    		},
    		emptystars: function() {
    			if (this.halfstar) {
    				return this.totalStars - (parseInt(this.stars) || 0) - 1;
    			} else {
    				return this.totalStars - (parseInt(this.stars) || 0);
    			}
    		}
    	}
    };
</script>