import { render, staticRenderFns } from "./PopupBox.vue?vue&type=template&id=20429620&scoped=true&"
import script from "./PopupBox.vue?vue&type=script&lang=js&"
export * from "./PopupBox.vue?vue&type=script&lang=js&"
import style0 from "./PopupBox.vue?vue&type=style&index=0&id=20429620&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../var/task/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20429620",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/task/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('20429620')) {
      api.createRecord('20429620', component.options)
    } else {
      api.reload('20429620', component.options)
    }
    module.hot.accept("./PopupBox.vue?vue&type=template&id=20429620&scoped=true&", function () {
      api.rerender('20429620', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "tmp/tmp-8vPeJR0OmeT1c/JS/comp/PopupBox.vue"
export default component.exports